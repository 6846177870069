.navbar {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Larger grain pattern for visibility */
  opacity: 0.3; /* Adjust transparency for subtlety */
  pointer-events: none; /* Non-interactive */
  z-index: 1; /* Keep texture above content */
}

.navbar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Scale up the texture for larger grains */
  opacity: 0.2; /* Increase visibility of the texture */
  pointer-events: none;
  z-index: -1;
  filter: contrast(1.1) brightness(0.9) grayscale(0.2);
}

/* Add this to your existing Navbar.css */
.nav-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1em;
  padding: 0;
  transition: color 0.3s ease;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.navbar-brand {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #f7efe5, #e2bfd9, #c8a1e0, #674188);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 12s infinite alternate linear;
}

.gradient-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #f7efe5, #e2bfd9, #c8a1e0, #674188);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 12s infinite alternate linear;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin-left: 20px;
}

.navbar-menu a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-menu a:hover {
  color: #0066cc;
}

@media (max-width: 600px) {
  .navbar-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-menu {
    margin-top: 10px;
  }

  .navbar-menu li {
    margin-left: 0;
    margin-right: 20px;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.nav-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1em;
  padding-bottom: 8px;
  position: relative;
  transition: color 0.3s ease;
}

.nav-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #f7efe5, #e2bfd9, #c8a1e0, #674188);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.nav-button:hover {
  color: #674188;
}

.nav-button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.hero-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 750px;
  margin: 0 auto; /* Centers the section horizontally */
  border-radius: 10px; /* Optional: adds rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  padding: 50px;
  background: linear-gradient(to right, #003a69, #674188);
  color: #fff;
}

.hero-content {
  max-width: 50%;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn.primary {
  background-color: #fff;
  color: #2575fc;
}

.btn.secondary {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn:hover {
  opacity: 0.8;
}

.hero-image {
  position: relative;
  max-width: 40%;
  border-radius: 10px;
  perspective: 500px; /* For 3D perspective */
   transition: transform 0.2s ease-out; 
}

.image{
  border-radius: 16px;
}

.image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.transparenttextures.com/patterns/paper-fibers.png'); /* Paper texture */
  background-size: 10%; /* Larger grain pattern for visibility */
  opacity: 0.3; /* Adjust transparency for subtlety */
  pointer-events: none; /* Non-interactive */
  z-index: 1; /* Keep texture above content */
}

.image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Scale up the texture for larger grains */
  opacity: 0.2; /* Increase visibility of the texture */
  pointer-events: none;
  z-index: -1;
  filter: contrast(1.1) brightness(0.9) grayscale(0.2);
}

.hero-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.transparenttextures.com/patterns/paper-fibers.png'); /* Paper texture */
  background-size: 10%; /* Larger grain pattern for visibility */
  opacity: 0.3; /* Adjust transparency for subtlety */
  pointer-events: none; /* Non-interactive */
  z-index: 1; /* Keep texture above content */
}

.hero-area::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Scale up the texture for larger grains */
  opacity: 0.2; /* Increase visibility of the texture */
  pointer-events: none;
  z-index: -1;
  filter: contrast(1.1) brightness(0.9) grayscale(0.2);
}

.paper {
  /* background-color: #fdfcf4; Off-white, paper-like base color */
  border-radius: 8px; /* Rounded corners for a soft look */
  padding: 1.5rem; /* Consistent padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.05); /* Subtle shadows */
  position: relative;
  overflow: hidden; /* Ensures any inner effects stay contained */
  color: #333; /* Neutral text color */
  /* font-family: 'Georgia', serif; Elegant font */
  line-height: 1.6; /* Readable line spacing */
}

.toggle-button-hero {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  transition: color 0.3s ease;
}

.toggle-button-hero::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #f7efe5, #e2bfd9, #c8a1e0, #674188);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.toggle-button-hero:hover {
  color: #b4b4b4;
}

.toggle-button-hero:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.info-section {
  background-color: rgba(255, 255, 255, 0.46);
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden; /* This will ensure the image doesn't overflow the rounded corners */
}

.restaurant-image-container {
  margin: -20px -20px 20px -20px; /* This will make the image extend to the edges of the info-section */
}

.restaurant-image {
  width: 100%;
  height: 300px; /* Adjust this value as needed */
  object-fit: cover;
  object-position: center;
}

.image-overlay {
  position: relative;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.image-text h1 {
  font-size: 2em;
  margin: 0;
}

.image-text p {
  font-size: 1.2em;
}

.info-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.info-item {
  background-color: rgba(255, 255, 255, 0.01);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.info-item h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.additional-info {
  margin-top: 20px;
  overflow: hidden;
}

.additional-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.additional-info.expanded .additional-content {
  max-height: 1000px; /* Adjust this value based on your content */
  opacity: 1;
}

.additional-info ul {
  list-style-type: none;
  padding: 0;
}

.additional-info li {
  margin-bottom: 10px;
}

.toggle-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.toggle-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  transition: color 0.3s ease;
}

.toggle-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #f7efe5, #e2bfd9, #c8a1e0, #674188);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.toggle-button:hover {
  color: #674188;
}

.toggle-button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.additional-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.additional-info.expanded .additional-content {
  max-height: 1000px; /* Adjust this value based on your content */
  opacity: 1;
}

@media (max-width: 600px) {
  .info-grid {
    grid-template-columns: 1fr;
  }
}

.paper {
  /* background-color: #fdfcf4; Off-white, paper-like base color */
  border-radius: 8px; /* Rounded corners for a soft look */
  padding: 1.5rem; /* Consistent padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.05); /* Subtle shadows */
  position: relative;
  overflow: hidden; /* Ensures any inner effects stay contained */
  color: #333; /* Neutral text color */
  /* font-family: 'Georgia', serif; Elegant font */
  line-height: 1.6; /* Readable line spacing */
}

/* Add noise texture overlay for realism */
.paper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Larger grain pattern for visibility */
  opacity: 0.3; /* Adjust transparency for subtlety */
  pointer-events: none; /* Non-interactive */
  z-index: 1; /* Keep texture above content */
}

.paper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
  background-size: 10%; /* Scale up the texture for larger grains */
  opacity: 0.2; /* Increase visibility of the texture */
  pointer-events: none;
  z-index: -1;
  filter: contrast(1.1) brightness(0.9) grayscale(0.2);
}

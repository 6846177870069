.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: rgba(255, 255, 255, 0.8);;
    padding: 20px;
    border-radius: 16px;
    max-width: 420px;
    width: 90%;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .modal-content li {
    margin-bottom: 10px;
  }
  
  .modal-content a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .modal-content a:hover {
    text-decoration: underline;
  }
  
  
  
  .close-button {
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 1em;
    padding: 10px 0;
    margin: 10px 0;
    position: relative;
    transition: color 0.3s ease;
  }
 
  .close-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      #F7EFE5,
      #E2BFD9,
      #C8A1E0,
      #674188
    );
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  .close-button:hover {
    color: #674188;
  }
  .close-button:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  
  /* Add noise texture overlay for realism */
  .modal-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
    background-size: 10%; /* Larger grain pattern for visibility */
    opacity: 0.3; /* Adjust transparency for subtlety */
    pointer-events: none; /* Non-interactive */
    z-index: 1; /* Keep texture above content */
  }
  
  .modal-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png"); /* Paper texture */
    background-size: 10%; /* Scale up the texture for larger grains */
    opacity: 0.2; /* Increase visibility of the texture */
    pointer-events: none;
    z-index: -1;
    filter: contrast(1.1) brightness(0.9) grayscale(0.2);
  }

  .section {
    margin-bottom: 20px;
  }
  .section h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .section ul {
    list-style-type: none;
    padding: 0;
  }
  .section ul li {
    margin-bottom: 5px;
  }
  .animated-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
  
  .animated-title.exiting {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .animated-title.entering {
    opacity: 1;
    transform: translateY(0);
  }
  
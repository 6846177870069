/* src/components/Footer.css */
.footer {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
}
.footer-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-menu li {
  margin-left: 20px;
}

.footer-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1em;
  padding: 0;
  transition: color 0.3s ease;
}

.footer-button:hover {
  color: #674188;
}
